import { createAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "js/hooks";
import { DeviceInfo, DeviceUpdate, DeviceUpdateLog } from "js/lib/deviceType";
import request from "js/lib/fetch";
import {
  editIntent,
  fetchFailed,
  RequestError,
  RequestErrorData,
  resetIntent,
} from "./errors";

export const getDevices = createAppAsyncThunk<
  DeviceInfo[],
  void,
  { rejectValue: RequestErrorData }
>("devices/get", async (_, { dispatch, getState, rejectWithValue }) => {
  const url = "/devices";

  const token = getState().auth.token;
  const options: RequestInit = {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  };
  try {
    return await request<DeviceInfo[]>(url, options);
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(fetchFailed({ ...error.error, intent: "fetch device info" }));
    return rejectWithValue(error.error);
  }
});

type DeviceUpdateLogWithDeviceSerial = {
  logs: DeviceUpdateLog[];
  serial: string | null;
};

async function requestLogs(
  url: string,
  config: RequestInit,
  serial: string
): Promise<DeviceUpdateLogWithDeviceSerial> {
  let logs = await request<DeviceUpdateLog[]>(url, config);
  return { logs, serial };
}

export const getDeviceLog = createAppAsyncThunk<
  DeviceUpdateLogWithDeviceSerial,
  string | null,
  { rejectValue: RequestErrorData }
>(
  "devices/getLog",
  async (deviceSerial, { dispatch, getState, rejectWithValue }) => {
    if (deviceSerial === null) {
      let empty: DeviceUpdateLogWithDeviceSerial = {
        logs: [],
        serial: deviceSerial,
      };
      return empty;
    }

    const url = `/devices/${deviceSerial}/update/logs`;
    const token = getState().auth.token;
    const options: RequestInit = {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    };
    try {
      return await requestLogs(url, options, deviceSerial);
    } catch (error) {
      if (!(error instanceof RequestError)) {
        throw error;
      }
      console.error(error);
      dispatch(
        fetchFailed({ ...error.error, intent: "fetch device update log" })
      );
      return rejectWithValue(error.error);
    }
  }
);

export const resetEditDevicesState = createAction(
  "devices/editDevice/resetState"
);

export const editDevices = createAppAsyncThunk<
  void,
  DeviceUpdate,
  { rejectValue: RequestErrorData }
>(
  "devices/editDevice",
  async (devicesUpdate, { dispatch, rejectWithValue, getState }) => {
    const url = "/devices";
    const token = getState().auth.token;
    try {
      await request<any>(url, {
        method: "PUT",
        headers: { Authorization: "Bearer " + token },
        body: JSON.stringify(devicesUpdate),
      });
    } catch (error) {
      if (!(error instanceof RequestError)) {
        throw error;
      }
      console.error(error);
      dispatch(fetchFailed({ ...error.error, intent: editIntent }));
      return rejectWithValue(error.error);
    }
  }
);

export const resetSetStateFailedOnDevicesState = createAction(
  "devices/setStateFailedOnDevices/resetState"
);

export const setStateFailedOnDevices = createAppAsyncThunk<
  void,
  string[],
  { rejectValue: RequestErrorData }
>(
  "devices/setStateFailedOnDevices",
  async (devices, { rejectWithValue, getState, dispatch }) => {
    const url = "/devices";
    const token = getState().auth.token;
    const update: DeviceUpdate = {
      serial_number: devices,
      set_state_failed: true,
    };
    try {
      await request<any>(url, {
        method: "PUT",
        headers: { Authorization: "Bearer " + token },
        body: JSON.stringify(update),
      });
    } catch (error) {
      if (!(error instanceof RequestError)) {
        throw error;
      }
      console.error(error);
      dispatch(fetchFailed({ ...error.error, intent: resetIntent }));
      return rejectWithValue(error.error);
    }
  }
);
