import { createReducer } from "@reduxjs/toolkit";
import { setToken } from "js/actions/auth";
import { fetchFailed, reasonInvalidToken } from "js/actions/errors";

interface Auth {
  readonly token?: string;
  readonly parsed: boolean
}

const emptyState: Auth = {
    parsed: false
};

const auth = createReducer(emptyState, (builder) => {
  builder.addCase(setToken, (state, action) => {
    if (action.payload) {
      state.token = action.payload;
    }
    state.parsed = true;
  });
  builder.addCase(fetchFailed, (state, action) => {
    // reset token if it's invalid (e.g. expired)
    if (action.payload.message === reasonInvalidToken) {
        state.token = undefined;
    }
  });
});

export default auth;