import { FC, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { useAppSelector } from "js/hooks";
import { getTheme } from "js/lib/theme";

const CustomThemeProvider: FC = ({ children }) => {
  const mode = useAppSelector(({ theme }) => theme.mode);
  const [theme, setTheme] = useState<Theme | undefined>(undefined);
  useEffect(() => {
    setTheme(getTheme(mode));
  }, [mode]);

  if (!theme) {
    return null;
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
