import React, { useEffect } from "react";
import { useAppDispatch } from "js/hooks";
import { setToken } from "js/actions/auth";
import { useSearchParams } from "react-router-dom";

function AuthProvider({ children }: { children: React.ReactNode }) : JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // token must be set after the # to be recognized by the hash router
    let token = searchParams.get("token");
    if (token !== null) {
      console.log(token)
      dispatch(setToken(token));
      searchParams.delete("token");
      setSearchParams(searchParams);
      return
    }
    dispatch(setToken(undefined));
  }, [dispatch, searchParams, setSearchParams])
  return (<>{children}</>);
}

export default AuthProvider;