import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadingIcon from "@mui/icons-material/Downloading";
import PendingIcon from "@mui/icons-material/Pending";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { GridFilterOperator, GridFilterItem, GridCellParams } from "@mui/x-data-grid";

// since we're using "icons" to portray the state on the table
// this is a custom operator for update state filtering
// not dry code because of the typing constraints but it works
export const UpdateStateIconGridFilterOperator: GridFilterOperator[] = [
  {
    value: "Failed",
    label: "Is failed",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      return (params: GridCellParams): boolean => {
        return params.value === filterItem.operatorValue;
      };
    },
    requiresFilterValue: false,
  },
  {
    value: "Done",
    label: "Is done",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      return (params: GridCellParams): boolean => {
        return params.value === filterItem.operatorValue;
      };
    },
    requiresFilterValue: false,
  },
  {
    value: "Updating",
    label: "Is updating",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      return (params: GridCellParams): boolean => {
        return params.value === filterItem.operatorValue;
      };
    },
    requiresFilterValue: false,
  },
  {
    value: "Assigned",
    label: "Is assigned",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      return (params: GridCellParams): boolean => {
        return params.value === filterItem.operatorValue;
      };
    },
    requiresFilterValue: false,
  },
];


type Props = {
  state: string | null;
};

const UpdateStateIcon = (props: Props) => {
  const { state } = props;
  let icon: ReactJSXElement;
  if (state === "Done") {
    icon = <CheckCircleIcon sx={{ color: "success.main" }} />;
  } else if (state === "Failed") {
    icon = <ErrorIcon sx={{ color: "error.main" }} />;
  } else if (state === "Updating") {
    icon = <DownloadingIcon sx={{ color: "primary.main" }} />;
  } else if (state === "Assigned") {
    icon = <PendingIcon sx={{ color: "charcoal.main" }} />;
  } else {
    return null;
  }

  return <Tooltip title={state}>{icon}</Tooltip>;
};

export default UpdateStateIcon;
