import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
    progress: number
    error: boolean
    success: boolean
}
const LogProgress = (props: Props) => {

    const { progress, error, success } = props;

    return (
        <>
            {/* this sx is to keep the icons centred/aligned  */}
            <Stack direction="row" spacing={1} sx={
                {
                    position: "relative",
                    display: "inline-flex",
                    alignProperty: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    width: "24px",
                    height: "24px",
                }
            }>  {/* ternary ?  avoid Logical && */}
                {error ? <CancelIcon sx={{ color: "error.main" }} /> : null}
                {success ? <CheckCircleIcon sx={{ color: "success.main" }} /> : null}   
                {!error && !success ? <CircularProgress size={24} variant="determinate" value={progress}
                    sx={progress === 100 ?
                        { color: "success.main" } : { color: "primary.main" }
                    }
                /> : null}
            </Stack>
        </>
    )
}

export default LogProgress;