import { HashRouter, Route, Routes } from "react-router-dom";
import Container from "@mui/material/Container";
import Header from "components/Header";
import ErrorBoundary from "components/errorBoundary";
import NotFound from "views/NotFound";
import Login from "./Login";
import UpdateLogs from "./UpdateLogs";
import Devices from "./Devices";
import AuthProvider from "components/AuthProvider";
import ProtectedPage from "components/ProtectedPage";
import Bundles from "./Bundles";
import Version from "components/Version";

const App = () => {
  return (
    <HashRouter>
      <div>
        <Header />
        <ErrorBoundary>
          <AuthProvider>
            <Container maxWidth="lg">
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedPage>
                      <Devices />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="/bundles"
                  element={
                    <ProtectedPage>
                      <Bundles />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="/updates-log/:id"
                  element={
                    <ProtectedPage>
                      <UpdateLogs />
                    </ProtectedPage>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/*" element={<NotFound />} />
              </Routes>
            </Container>
          </AuthProvider>
        </ErrorBoundary>
        <Version />
      </div>
    </HashRouter>
  );
};

export default App;
