import { PaletteMode } from "@mui/material";
import produce from "immer";
import { setPaletteMode, toggleDarkMode } from "js/actions/theme";
import { AnyAction } from "redux";

interface Theme {
  readonly mode: PaletteMode;
}

const initialState: Theme = { mode: "light" };

export default function theme(state = initialState, action: AnyAction) {
  if (toggleDarkMode.match(action)) {
    state = produce(state, (draft) => {
      draft.mode = draft.mode === "light" ? "dark" : "light";
    });
  } else if (setPaletteMode.match(action)) {
    state = produce(state, (draft) => {
      draft.mode = action.payload;
    });
  }
  return state;
}
