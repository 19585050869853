import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LogEntry from "components/LogEntry";
import { clearUpdateLog, getUpdateLog } from "js/actions/update";
import { useAppDispatch, useAppSelector } from "js/hooks";
import { UpdateInfo } from "js/lib/updateType";
import { useEffect, useState } from "react";
import UpdateList from "components/UpdateList";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { Theme, alpha, darken, lighten } from "@mui/material/styles";

function getBorderColor(theme: Theme) {
  if (theme.palette.mode === "light") {
    return lighten(alpha(theme.palette.divider, 1), 0.88);
  }
  return darken(alpha(theme.palette.divider, 1), 0.68);
}

const UpdatesLogs = () => {
  const dispatch = useAppDispatch();
  const updateLog = useAppSelector((state) => state.update.updateLogById);
  const [selected, setSelected] = useState<UpdateInfo | undefined>(undefined);
  const { id: deviceSerial } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(clearUpdateLog()); // reset update logs when we get a new serial
  }, [dispatch, deviceSerial]);

  useEffect(() => {
    if (selected?.id === undefined) return;
    dispatch(getUpdateLog(selected?.id));
    const interval = setInterval(() => {
      dispatch(getUpdateLog(selected?.id));
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch, selected?.id]);

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Update Logs
      </Typography>
      <Paper sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <UpdateList setSelected={setSelected} deviceSerial={deviceSerial} />
          </Box>
          <Box
            sx={{
              flex: 1,
              border: 1,
              borderColor: getBorderColor,
              borderRadius: 1,
            }}
          >
            {selected?.assigned_by && (
              <>
                <Typography
                  variant="body2"
                  textAlign={"center"}
                  sx={{ lineHeight: "56px" }}
                >
                  {`Assigned by: ${selected?.assigned_by}`}
                </Typography>
                <Divider sx={{ borderColor: getBorderColor }} />
              </>
            )}
            <Stack sx={{ maxHeight: "800px", overflowY: "auto", p: 1 }}>
              {!updateLog?.length && (
                <Typography variant="body1" textAlign={"center"} m={25}>
                  No logs available, please select an update from the table on
                  the left.
                </Typography>
              )}
              {updateLog?.map((log, index) => (
                <LogEntry logEntry={log} key={index} />
              ))}
            </Stack>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default UpdatesLogs;
