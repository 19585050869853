import { combineReducers } from "redux";
import errors from "js/reducers/errors";
import auth from "js/reducers/auth";
import devices from "js/reducers/devices";
import bundles from "js/reducers/bundles";
import update from "js/reducers/update";
import theme from "js/reducers/theme";
import projects from "js/reducers/projects";

export default combineReducers({
  errors,
  auth,
  devices,
  bundles,
  update,
  projects,
  theme,
});
