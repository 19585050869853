import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DarkmodeToggleButton from "./darkmodeToggleButton";

export const pages = [
  { title: "Devices", path: "/" },
  { title: "Bundles", path: "/bundles" },
  { title: "Updates Log", path: "/updates-log/all" },
];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ color: "white" }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ display: "flex" }}>
          <Link
            href="/"
            noWrap
            variant="h6"
            sx={{
              mr: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              style={{ marginTop: "7px" }}
              className="logo"
              src={process.env.PUBLIC_URL + "/logo.svg"}
              alt="Riedel"
            />
            <Typography variant="h5" component="h2" sx={{ ml: 1 }}>
              Update-Server
            </Typography>
          </Link>

          <Box
            sx={{
              flexGrow: 1,
              ml: "auto",
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="app menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map(({ title, path }) => (
                <MenuItem key={title} href={path} component={Link}>
                  <Typography textAlign="center">{title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(({ title, path }) => (
              <Button
                key={title}
                href={path}
                sx={{ my: 2, color: "inherit", display: "block" }}
              >
                {title}
              </Button>
            ))}
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <DarkmodeToggleButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
