import { useAppSelector } from "js/hooks";
import React, { useEffect} from "react";

function redirect() {
  window.location.href = "/auth/login";
}

function ProtectedPage({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const {token, parsed} = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (!token && parsed) {
      redirect();
    }
  }, [token, parsed]);
  return <>{token === undefined ? null : children}</>;
}

export default ProtectedPage;
