import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { RequestErrorData } from "js/actions/errors";
import {
  addUpdate,
  getUpdates,
  resetAddUpdateState,
  getUpdateLog,
  clearUpdateLog,
} from "js/actions/update";
import { DeviceUpdateLog } from "js/lib/deviceType";
import { RequestState } from "js/lib/fetch";
import {
  UpdateInfo,
  UpdateInsertErrorEntry,
  UpdateInsertErrorEntryMessage,
} from "js/lib/updateType";

interface UpdateInsertErrorMap {
  [key: string]: UpdateInsertErrorEntry[];
}

interface Update {
  readonly addRequest: RequestState;
  readonly updates: UpdateInfo[];
  readonly updateLogById: DeviceUpdateLog[] | null;
  readonly updateInsertError: UpdateInsertErrorEntry[];
  readonly updateInsertErrorFiltered: UpdateInsertErrorMap;
}

const initialState: Update = {
  addRequest: {
    pending: false,
    fulfilled: false,
  },
  updates: [],
  updateLogById: null,
  updateInsertError: [],
  updateInsertErrorFiltered: {},
};

type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};

const handleRequest = function <
  P extends keyof PickByType<Update, RequestState>,
  A,
  B
>(
  builder: ActionReducerMapBuilder<Update>,
  thunk: AsyncThunk<A, B, { rejectValue: RequestErrorData }>,
  name: P
) {
  builder.addCase(thunk.pending, (state, action) => {
    state[name].pending = true;
    state[name].fulfilled = false;
    state[name].error = undefined;
  });
  builder.addCase(thunk.fulfilled, (state, action) => {
    state[name].pending = false;
    state[name].fulfilled = true;
  });
  builder.addCase(thunk.rejected, (state, action) => {
    state[name].pending = false;
    state[name].fulfilled = false;
    state[name].error = action.payload;

    if (action.payload?.errorList === undefined) return;
    state.updateInsertError = action.payload.errorList;

    Object.values(UpdateInsertErrorEntryMessage).forEach(
      (v) =>
        (state.updateInsertErrorFiltered[v] = state.updateInsertError.filter(
          (u) => u.message === v
        ))
    );
  });
};

const config = createReducer(initialState, (builder) => {
  handleRequest(builder, addUpdate, "addRequest");
  builder.addCase(resetAddUpdateState, (state, action) => {
    state.addRequest.fulfilled = false;
    state.addRequest.pending = false;
    state.addRequest.error = undefined;
    state.updateInsertError = [];
    state.updateInsertErrorFiltered = {};
  });
  builder.addCase(getUpdates.fulfilled, (state, action) => {
    state.updates = action.payload;
  });
  builder.addCase(getUpdateLog.fulfilled, (state, action) => {
    state.updateLogById = action.payload;
  });
  builder.addCase(clearUpdateLog, (state, action) => {
    state.updateLogById = null;
  });
});

export default config;
