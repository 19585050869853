import { getUpdates } from "js/actions/update";
import { useAppDispatch, useAppSelector } from "js/hooks";
import { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
} from "@mui/x-data-grid/";
import UpdateStateIcon, {
  UpdateStateIconGridFilterOperator,
} from "components/UpdateStateIcon";
import RelativeTime from "components/RelativeTime";
import { UpdateInfo } from "js/lib/updateType";
import BundleVersion from "./BundleVersion";

const columns: GridColDef[] = [
  { field: "host_name", headerName: "Host Name", minWidth: 100, flex: 1 },
  { field: "serial_number", headerName: "Serial" },
  {
    field: "version",
    headerName: "Version",
    minWidth: 150,
    renderCell: (params) => {
      return <BundleVersion version={params.value} />;
    },
  },
  {
    field: "state",
    headerName: "State",
    width: 30,
    renderCell: (params) => {
      return <UpdateStateIcon state={params.value} />;
    },
    filterOperators: UpdateStateIconGridFilterOperator,
  },
  {
    field: "last_state_update_at",
    headerName: "Changed At",
    width: 140,
    renderCell: (params) => {
      return <RelativeTime time={params.value} />;
    },
  },
];

type Props = {
  setSelected: (update: UpdateInfo | undefined) => void;
  deviceSerial?: string;
};

const UpdateList = (props: Props) => {
  const { setSelected, deviceSerial } = props;
  const updates = useAppSelector((state) => state.update.updates);
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<GridFilterItem[]>([
    {
      columnField: "serial_number",
      operatorValue: "contains",
      value: deviceSerial,
    },
  ]);

  useEffect(() => {
    if (deviceSerial === "all") {
      setFilter([]);
    }
  }, [deviceSerial]);

  useEffect(() => {
    dispatch(getUpdates());
    const interval = setInterval(() => {
      dispatch(getUpdates());
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <DataGrid
      rows={updates}
      columns={columns}
      rowsPerPageOptions={[20]}
      pageSize={20}
      onSelectionModelChange={(rowId) => {
        const update = updates.find((update) => update.id === Number(rowId));
        setSelected(update);
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "last_state_update_at", sort: "desc" }],
        },
      }}
      autoHeight={true}
      sx={{ width: "100%", minHeight: { xs: "300px", md: "400px" } }}
      filterModel={{ items: filter }}
      onFilterModelChange={(model: GridFilterModel) => {
        // allow the user to change the filer
        setFilter(model.items);
      }}
    />
  );
};

export default UpdateList;
