import "styles/index.scss";
import "js/lib/immer";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "js/store";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "components/ThemeProvider";
import App from "views/App";
import persist from "js/lib/persistence";
import { setPaletteMode } from "js/actions/theme";
import { PaletteMode } from "js/lib/theme";

// sync darkMode to localStorage
persist(
  store,
  "paletteMode",
  (state) => state.theme.mode,
  (mode) => store.dispatch(setPaletteMode(mode as PaletteMode))
);

console.log("store initial", store.getState());

// render App element
render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
