import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  Snackbar,
} from "@mui/material";
import { getBundles } from "js/actions/bundles";
import { addUpdate, resetAddUpdateState } from "js/actions/update";
import { useAppDispatch, useAppSelector } from "js/hooks";
import { useCallback, useEffect, useState } from "react";
import { DeviceInfo } from "js/lib/deviceType";
import { clearError, setError } from "js/actions/errors";
import { UpdateInsertErrorEntryMessage } from "js/lib/updateType";
import {
  resetSetStateFailedOnDevicesState,
  setStateFailedOnDevices,
} from "js/actions/devices";
import { AppDispatch } from "js/store";
import BundleVersion from "./BundleVersion";

type UpdateDialogProps = {
  open: boolean;
  selected: DeviceInfo[];
  handleClose: () => void;
};

const updateIntent = "assign update";

function getListText(serial_number: string, devices: DeviceInfo[]): string {
  const hostname = devices.find(
    (d) => d.serial_number === serial_number
  )?.host_name;
  return `Serial: ${serial_number}, Name: ${hostname}`;
}

export function prepareUpdateDialog(dispatch: AppDispatch) {
  dispatch(resetSetStateFailedOnDevicesState());
  dispatch(resetAddUpdateState());
}

function UpdateDialog({ open, selected, handleClose }: UpdateDialogProps) {
  const dispatch = useAppDispatch();
  const devices = useAppSelector((state) => state.devices.devices);
  const versions = useAppSelector((state) => state.bundles.versions);
  const addRequest = useAppSelector((state) => state.update.addRequest);
  const resetRequest = useAppSelector((state) => state.devices.setResetRequest);
  const updateErrorList = useAppSelector(
    (state) => state.update.updateInsertError
  );
  const updateErrorListFiltered = useAppSelector(
    (state) => state.update.updateInsertErrorFiltered
  );
  const [selectedVersion, setSelectedVersion] = useState("");

  const handleListItemClick = (index: string) => {
    setSelectedVersion(index);
  };

  const closeAndResetState = useCallback(() => {
    handleClose();
    // reset the state, after we consumed it
    dispatch(resetAddUpdateState());
  }, [handleClose, dispatch]);

  useEffect(() => {
    if (addRequest.fulfilled) {
      handleClose();
      setSelectedVersion("");
      dispatch(clearError({ intent: updateIntent, message: "" }));
    }
  }, [addRequest.fulfilled, handleClose, setSelectedVersion, dispatch]);

  useEffect(() => {
    if (!open) return;
    // check select count on open
    if (selected.length === 0) {
      dispatch(
        setError({
          intent: updateIntent,
          message: "Please select at least one device to update",
        })
      );
      handleClose();
    } else {
      dispatch(clearError({ intent: updateIntent, message: "" }));
      dispatch(getBundles());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, JSON.stringify(selected.map((s) => s.serial_number).sort())]);

  useEffect(() => {
    if (!open || selectedVersion === "" || selected.length === 0) return;
    if (resetRequest.fulfilled) {
      // we have done a reset request from our dialog
      // lets try the update again
      dispatch(
        addUpdate({
          version: selectedVersion,
          serial_number: selected.map((v) => v.serial_number),
        })
      );
      dispatch(resetSetStateFailedOnDevicesState());
      return;
    }
    if (resetRequest.error !== undefined) {
      // reset state has some errors, we end our dialog here
      // the error will be displayed by the other dialog
      handleClose();
    }
  }, [resetRequest, open, dispatch, selectedVersion, selected, handleClose]);

  return (
    <>
      <Snackbar
        open={addRequest.fulfilled}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(resetAddUpdateState());
        }}
      >
        <Alert
          onClose={() => {
            dispatch(resetAddUpdateState());
          }}
          severity="success"
        >
          Updates created.
        </Alert>
      </Snackbar>
      <Dialog
        open={open && updateErrorList.length === 0 && !resetRequest.pending}
        onClose={handleClose}
      >
        <DialogTitle>Please choose a version to update to</DialogTitle>
        <DialogContent>
          <List>
            {versions.map((v) => (
              <ListItemButton
                selected={selectedVersion === v}
                onClick={() => handleListItemClick(v)}
                key={v}
              >
                <ListItemText primary={<BundleVersion version={v} />} />
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="charcoal"
            variant="contained"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              console.log(selectedVersion);
              if (selectedVersion === "") {
                dispatch(
                  setError({
                    intent: updateIntent,
                    message: "Please select a version for update.",
                  })
                );
                handleClose();
                return;
              }
              dispatch(clearError({ intent: updateIntent, message: "" }));
              dispatch(
                addUpdate({
                  version: selectedVersion,
                  serial_number: selected.map((v) => v.serial_number),
                })
              );
            }}
          >
            Assign Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open && updateErrorList.length > 0}
        onClose={closeAndResetState}
      >
        <DialogTitle>
          There occurred some errors, when we try to insert the update:
        </DialogTitle>
        <DialogContent>
          <List aria-labelledby="nested-list-subheader">
            {Object.keys(updateErrorListFiltered).map((msg) => {
              if (updateErrorListFiltered[msg].length === 0) return "";
              return (
                <List key={msg + "list"}>
                  <ListItemText primary={msg} key={msg} />
                  {updateErrorListFiltered[msg].map((v) => (
                    <ListItemText
                      primary={getListText(v.serial_number, devices)}
                      sx={{ pl: 4 }}
                      key={v.serial_number}
                    />
                  ))}
                </List>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="warning"
            variant="contained"
            onClick={closeAndResetState}
          >
            Abort
          </Button>
          {
            //only allow the state reset if there aren't any other error messages
            updateErrorList.length -
              updateErrorListFiltered[UpdateInsertErrorEntryMessage.WRONG_STATE]
                ?.length ===
            0 ? (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  dispatch(
                    setStateFailedOnDevices(
                      updateErrorListFiltered[
                        UpdateInsertErrorEntryMessage.WRONG_STATE
                      ].map((v) => v.serial_number)
                    )
                  );
                  dispatch(resetAddUpdateState());
                }}
              >
                Reset State and try again
              </Button>
            ) : null
          }
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpdateDialog;
