import React from "react";
import { createTheme, PaletteOptions } from "@mui/material/styles";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from "@mui/material/Link";

// @ts-ignore - webpack css parser does not offer any types
import lightStyles from "styles/lightTheme.scss";
// @ts-ignore - webpack css parser does not offer any types
import darkStyles from "styles/darkTheme.scss";
// @ts-ignore - webpack css parser does not offer any types
import fonts from "styles/fonts.scss";

export type PaletteMode = "light" | "dark";

// Use react router link behavior
export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

declare module "@mui/material/styles" {
  interface Palette {
    charcoal: Palette["primary"];
  }
  interface PaletteOptions {
    charcoal: PaletteOptions["primary"];
  }
  interface PaletteColor {
    charcoal?: string;
  }
  interface SimplePaletteColorOptions {
    charcoal?: string;
  }
}

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    charcoal: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    charcoal: true;
  }
}

console.log(lightStyles);

const getPalette = (mode: PaletteMode): PaletteOptions => {
  const styles = mode === "light" ? lightStyles : darkStyles;
  return {
    mode,
    primary: {
      main: styles["primary-color"],
      light: styles["primary-color-lighter"],
      dark: styles["primary-color-darker"],
      contrastText: styles["text-primary-color"],
    },
    secondary: {
      main: styles["secondary-color"],
      light: styles["secondary-color-lighter"],
      dark: styles["secondary-color-darker"],
      contrastText: styles["text-secondary-color"],
    },
    success: {
      main: styles["success-color"],
      light: styles["success-color-lighter"],
      dark: styles["success-color-darker"],
      contrastText: styles["text-success-color"],
    },
    warning: {
      main: styles["warning-color"],
      light: styles["warning-color-lighter"],
      dark: styles["warning-color-darker"],
      contrastText: styles["text-warning-color"],
    },
    error: {
      main: styles["error-color"],
      light: styles["error-color-lighter"],
      dark: styles["error-color-darker"],
      contrastText: styles["text-error-color"],
    },
    info: {
      main: styles["info-color"],
      light: styles["info-color-lighter"],
      dark: styles["info-color-darker"],
      contrastText: styles["text-info-color"],
    },
    charcoal: {
      main: styles["charcoal-color"],
      light: styles["charcoal-color-lighter"],
      dark: styles["charcoal-color-darker"],
      contrastText: styles["text-charcoal-color"],
    },
    background: {
      default: styles["background-color"],
    },
    divider: styles["divider-color"],
    text: {
      // primary: styles["text-color-primary"],
      // secondary: stylesLight["text-color-secondary"],
      // disabled: stylesLight["text-color-disabled"],
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  };
};

// MUI Theme created from riedel css styles
// Todo: finish dark theme/light theme
export const getTheme = (mode: PaletteMode) =>
  createTheme({
    typography: {
      fontFamily: [fonts.riedelFont].join(","),
    },
    palette: getPalette(mode),
    components: {
      MuiAppBar: {
        defaultProps: {
          color: "charcoal",
          enableColorOnDark: true,
        },
        styleOverrides: {
          root: {
            boxShadow: "0 5px 7px #0000004d",
            backgroundImage: "none",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
          disableRipple: true,
        },
        styleOverrides: {
          // root: ({ ownerState, theme }) => {
          //   console.log(ownerState.color,theme)
          //   return {
          //     "&:focus": {bgcolor:ownerState.color+".light"},
          // }}
          // root:{
          //   "&:focus":{ outline:"1px solid", outlineColor:"primary.main"}
          // }
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => {
            if (theme.palette.mode === "dark") {
              return {
                backgroundColor: theme.palette.background.default,
              };
            }
          },
        },
      },
    },
  });
