import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import IconButton from "@mui/material/IconButton";
import { useAppSelector, useAppDispatch } from "js/hooks";
import { toggleDarkMode } from "js/actions/theme";

const DarkmodeToggleButton = () => {
  const mode = useAppSelector(({ theme }) => theme.mode);
  const dispatch = useAppDispatch();
  return (
    <IconButton
      sx={{ ml: 1 }}
      onClick={() => dispatch(toggleDarkMode())}
      color="inherit"
    >
      {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};

export default DarkmodeToggleButton;
