export interface BundleInfo {
  file_name: string | null;
  version: string;
  arch: string;
  build_at: string | null;
  description: string | null;
}

export function isBetaVersion(version: string | undefined): boolean {
  if (version === undefined || version === null) return false;
  return version.includes("-");
}
