import { Store } from "redux";

let storageSupported = new Map();

// selectively sync redux store state to local/session storage key
const persist = <S>(
  store: Store<S>,
  key: string,
  read: (state: S) => string,
  write: (prop: string) => any,
  storage = localStorage
) => {
  // only fail on storage once
  if (storageSupported.has(storage) && !storageSupported.get(storage)) return;

  // read initial state
  let prop: string | null;
  try {
    prop = storage.getItem(key);
  } catch (err) {
    storageSupported.set(storage, false);
    console.warn("Warning: local/session storage read is disabled");
    return;
  }

  // report back to user
  if (prop) write(prop);

  // continously watch prop changes
  const unsubscribe = store.subscribe(() => {
    const newProp = String(read(store.getState()));
    if (newProp === prop) return;

    try {
      prop = newProp;
      storage.setItem(key, prop);
    } catch (e) {
      // stop watching if write fails
      unsubscribe();
      console.warn("Warning: local/session storage write is disabled");
    }
  });
};
export default persist;
