import { createAction } from "@reduxjs/toolkit";
import request from "js/lib/fetch";
import { UpdateInfo } from "js/lib/updateType";
import { fetchFailed, RequestError, RequestErrorData } from "./errors";
import { createAppAsyncThunk } from "js/hooks";
import { DeviceUpdateLog } from "js/lib/deviceType";

interface NewUpdate {
  version: string;
  serial_number: string[];
}

export const resetAddUpdateState = createAction("update/addUpdate/resetState");

export const addUpdate = createAppAsyncThunk<
  void,
  NewUpdate,
  { rejectValue: RequestErrorData }
>(
  "update/addUpdate",
  async (update, { dispatch, getState, rejectWithValue }) => {
    const url = "/update";
    const token = getState().auth.token;
    try {
      await request<any>(url, {
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        body: JSON.stringify(update),
      });
    } catch (error) {
      if (!(error instanceof RequestError)) {
        throw error;
      }
      console.error(error);
      dispatch(fetchFailed({ ...error.error, intent: "assign update" }));
      return rejectWithValue(error.error);
    }
  }
);

export const getUpdates = createAppAsyncThunk<
  UpdateInfo[],
  void,
  { rejectValue: RequestErrorData }
>("update/get", async (_, { dispatch, getState, rejectWithValue }) => {
  const url = "/update";

  const token = getState().auth.token;
  const options: RequestInit = {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  };
  try {
    return await request<UpdateInfo[]>(url, options);
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(fetchFailed({ ...error.error, intent: "fetch update info" }));
    return rejectWithValue(error.error);
  }
});

// get update log by id
export const getUpdateLog = createAppAsyncThunk<
  DeviceUpdateLog[],
  number | undefined,
  { rejectValue: RequestErrorData }
>(
  "update/getLogs",
  async (updateId, { dispatch, getState, rejectWithValue }) => {
    if (updateId === undefined) {
      return [];
    }
    const url = "/update/" + updateId + "/logs";
    const token = getState().auth.token;
    const options: RequestInit = {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    };
    try {
      const response = await request<DeviceUpdateLog[]>(url, options);
      return response;
    } catch (error) {
      if (!(error instanceof RequestError)) {
        throw error;
      }
      console.error(error);
      dispatch(fetchFailed({ ...error.error, intent: "fetch update log" }));
      return rejectWithValue(error.error);
    }
  }
);

export const clearUpdateLog = createAction("update/clearLogs");
