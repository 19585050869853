import { createAppAsyncThunk } from "js/hooks";
import { BundleInfo } from "js/lib/bundleType";
import request from "js/lib/fetch";
import { fetchFailed, RequestError, RequestErrorData } from "./errors";

export const getBundles = createAppAsyncThunk<
  BundleInfo[],
  void,
  { rejectValue: RequestErrorData }
>("bundles/get", async (_, { dispatch, getState, rejectWithValue }) => {
  const url = "/bundle";

  const token = getState().auth.token;
  const options: RequestInit = {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  };
  try {
    return await request<BundleInfo[]>(url, options);
  } catch (error) {
    if (!(error instanceof RequestError)) {
      throw error;
    }
    console.error(error);
    dispatch(fetchFailed({ ...error.error, intent: "fetch bundle info" }));
    return rejectWithValue(error.error);
  }
});
