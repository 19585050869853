import { createReducer } from "@reduxjs/toolkit";
import { getBundles } from "js/actions/bundles";
import { BundleInfo } from "js/lib/bundleType";

interface Bundles {
  readonly bundles: BundleInfo[];
  readonly versions: string[];
}

const emptyState: Bundles = {
  bundles: [],
  versions: [],
};

const devices = createReducer(emptyState, (builder) => {
  builder.addCase(getBundles.fulfilled, (state, action) => {
    state.bundles = action.payload;
    let versions = action.payload
      .filter((v, i, a) => v.file_name !== null)
      .map((b) => b.version);
    state.versions = versions.filter((v, i, a) => a.indexOf(v) === i);
  });
});

export default devices;
